<template>
	<v-row>
		<v-col>
			<v-card>
				<v-card-title>
					<v-row>
						<v-col class="px-1 pl-3">Margem</v-col>
						<v-col class="px-1">
							<v-select
								clearable
								:items="filiais.lista"
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								v-model.number="busca.idfilial"
								@change="get()"
								class="mb-n7"
							></v-select>
						</v-col>
						<v-col class="px-1 pr-3">
							<v-text-field
								clearable
								label="Produto"
								outlined
								dense
								v-model.number="busca.idproduto"
								@change="get()"
								class="mb-n7"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-title>
				<v-divider />
				<v-card-text class="mt-5">
					<v-row no-gutters>
						<v-col cols="12" md="3" class="px-1">
							<v-text-field
								v-model="dados.custoultimacompra"
								label="Custo última compra"
								outlined
								dense
								persistent-placeholder
							/>
						</v-col>
						<v-col cols="12" md="3" class="px-1">
							<v-text-field :value="imposto()" label="Impostos" outlined dense persistent-placeholder />
						</v-col>
						<v-col cols="12" md="3" class="px-1">
							<v-text-field
								v-model="dados.venda"
								label="Preço de venda"
								outlined
								dense
								persistent-placeholder
							/>
						</v-col>
						<v-col cols="12" md="3" class="px-1">
							<v-text-field :value="lucro()" label="Lucro" outlined dense persistent-placeholder />
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12" md="3" class="px-1">
							<v-text-field :value="dados.pis" label="PIS" outlined dense persistent-placeholder />
						</v-col>
						<v-col cols="12" md="3" class="px-1">
							<v-text-field :value="dados.cofins" label="Cofins" outlined dense persistent-placeholder />
						</v-col>
						<v-col cols="12" md="3" class="px-1">
							<v-text-field :value="reducao()" label="Redução" outlined dense persistent-placeholder />
						</v-col>
						<v-col cols="12" md="3" class="px-1">
							<v-text-field :value="margem()" label="Margem" outlined dense persistent-placeholder />
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-title class="mt-n6">Fórmulas</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row no-gutters>
						<v-col cols="12" class="px-1 text-body-1 font-weight-bold">Margem = Lucro / Preço de Venda</v-col>
						<v-col
							cols="12"
							class="px-1 text-body-1 font-weight-bold"
						>Lucro = Preço de Venda - Impostos - Custo Última Compra</v-col>
						<v-col cols="12" class="px-1 text-body-1 font-weight-bold">Impostos = PIS + Cofins + Redução</v-col>
						<v-col cols="12" class="px-1 text-body-1 font-weight-bold">Redução = ICMS - (ICMS * Redução)</v-col>
						<v-col
							cols="12"
							class="px-1 text-end"
						>* Só calcula redução quando a aliquota de PIS e Cofins é diferente de 1,65 % e 7,60 %</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";

export default {
	name: "TiCalculos",
	mixins: [mixinFilial],
	data: () => ({
		carregando: false,
		dados: {},
		busca: { idproduto: null, idfilial: null },
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		get() {
			if (!this.busca.idfilial || !this.busca.idproduto) return;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}ti/calculos`, {
					idproduto: this.busca.idproduto,
					idempresa: this.busca.idfilial,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		imposto() {
			let pis = this.dados.venda * (this.dados.pis / 100);
			let cofins = this.dados.venda * (this.dados.cofins / 100);
			let reducao =
				this.dados.venda *
				((this.dados.icmssaida -
					this.dados.icmssaida *
						(this.dados.reducaotributaria / 100)) /
					100);
			let imposto = pis + cofins + reducao;
			if (isNaN(imposto)) imposto = 0;
			return imposto.toFixed(6);
		},
		lucro() {
			let lucro =
				this.dados.venda -
				this.dados.custoultimacompra -
				this.imposto();
			if (isNaN(lucro)) lucro = 0;
			return lucro.toFixed(6);
		},
		margem() {
			let margem = this.lucro() / this.dados.venda;
			if (isNaN(margem)) margem = 0;
			return (margem * 100).toFixed(6);
		},
		reducao() {
			let reducao =
				this.dados.icmssaida -
				this.dados.icmssaida * (this.dados.reducaotributaria / 100);
			if (isNaN(reducao)) reducao = 0;
			return reducao.toFixed(2);
		},
	},
};
</script>

